import { render, staticRenderFns } from "./reader.vue?vue&type=template&id=09ddb7ac&scoped=true&"
import script from "./reader.vue?vue&type=script&lang=js&"
export * from "./reader.vue?vue&type=script&lang=js&"
import style0 from "./reader.vue?vue&type=style&index=0&id=09ddb7ac&lang=scss&scoped=true&"
import style1 from "./reader.vue?vue&type=style&index=1&id=09ddb7ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09ddb7ac",
  null
  
)

export default component.exports